import React from 'react'
// components.
import HomepageLayout from 'src/templates/homepage'
import PageHead from 'src/components/head/PageHead'

const PompPage = () => <HomepageLayout noIndex />

export default PompPage

export function Head() {
  return <PageHead page="home" noIndex />
}
